import { createStyles, makeStyles } from '@material-ui/core';
import { CSSProperties, FunctionComponent } from 'react';

const useStyles = makeStyles(() => createStyles({
    root: {
        flex: '0 0 auto',
        width: '80%',
        height: '2rem',
        textAlign: 'center',
        '&:before': {
            content: `''`,
            display: 'block',
            width: '40%',
            height: '2rem',
            borderBottom: '1px solid #fff',
            position: 'relative',
            left: '0',
            top: '-1.3rem',
            float: 'left'
        },
        '&:after': {
            content: `''`,
            display: 'block',
            width: '40%',
            height: '2rem',
            borderBottom: '1px solid #fff',
            position: 'relative',
            right: '0',
            top: '-1.3rem',
            float: 'right'
        }
    }
}));

export const Divider: FunctionComponent<{className?: string, style?: CSSProperties}> = ({className, style, children}): JSX.Element => {
    const classes = useStyles();
    return <div className={className ? `${classes.root} ${className}` : classes.root} style={style}><span>{children}</span></div>;
};