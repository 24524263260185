import { createStyles, List, withStyles } from '@material-ui/core';
import { colors, translateHexRgba } from '../Theme';

export const ScrollableCharacterList = withStyles(() =>
    createStyles({
        root: {
            width: '100%',
            height: 'calc(100% - 82px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '.5rem'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: `inset 0 0 .2rem ${translateHexRgba(colors.uncommon, .5)}`
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: translateHexRgba(colors.uncommon, .1),
                outline: 'none',
                borderRadius: '1rem'
            }
        }
    })
)(List);