import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Item, ItemTooltip as IItemTooltip } from 'shared/Item';
import { colors } from '../Theme';

export const useStyles = makeStyles(() => createStyles({
    root: {
        position: 'fixed',
        width: '480px',
        zIndex: 100000,
        background: 'rgba(0, 0, 0, .8)',
        alignContent: 'flex-start',
        padding: '.5rem',
        border: `1px solid ${colors.poor}`,
        borderRadius: '.3rem'
    }
}));

interface ItemTooltipProps {
    item: Item;
    mouseX?: number;
    mouseY?: number;
}

export const ItemTooltip: FunctionComponent<ItemTooltipProps> = ({item, mouseX, mouseY}: ItemTooltipProps): JSX.Element => {
    const classes = useStyles();
    const [posY, setPosY] = useState<number>(mouseY || 0);
    const rootRef: MutableRefObject<HTMLDivElement|undefined> = useRef<HTMLDivElement>();

    useEffect(() => {
        if (rootRef.current) {
            const bodyRect: DOMRect = document.body.getBoundingClientRect();
            const tooltipRect: DOMRect = rootRef.current.getBoundingClientRect();
            const offsetTop: number = bodyRect.height - tooltipRect.top;
            if (offsetTop < tooltipRect.height) setPosY(bodyRect.height - 16 - tooltipRect.height);
        }
    }, []);

    return <Grid container className={classes.root} style={{left: mouseX || 0, top: posY}} innerRef={rootRef}>
        {item.tooltip.map((tooltip: IItemTooltip, index: number) => <Grid key={`item-tooltip-label-${index}`} item xs={tooltip.format === 'alignRight' ? 3 : 9}>
            <Typography style={{
                color: index === 0 ? colors[item.quality.toLowerCase()] :
                    tooltip.format && tooltip.format !== 'indent' && tooltip.format !== 'alignRight' ? colors[tooltip.format?.toLowerCase()] : undefined
            }}>{tooltip.label}</Typography>
        </Grid>)}
    </Grid>;
};