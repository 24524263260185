import { Container, Drawer, Fab, Grid, Hidden, Snackbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { ClassNameMap, ThemeProvider } from '@material-ui/styles';
import { LocalizationProvider } from '@sml86/i18njs';
import { Context, createContext, Dispatch, FunctionComponent, MutableRefObject, ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Character } from 'shared/Character';
import { Run as IRun } from 'shared/Run';
import { Session as SessionParameter } from 'shared/Session';
import { User } from 'shared/User';
import { API } from './api/API';
import { useStyles } from './AppStyles';
import { MainMenu } from './menu/MainMenu';
import { Run } from './run/Run';
import { getSession, SessionProperties } from './session/Session';
import { Start } from './start/Start';
import { theme } from './Theme';
import { VideoBackground } from './videobackground/VideoBackground';

export interface AppProperties {
    openDrawer: (content: ReactNode, position?: 'bottom'|'left'|'right') => void;
    closeDrawer: (callback?: () => void) => void;
    openErrorToast: (message: ReactNode, callback?: () => void) => void;
    closeErrorToast: () => void;
    session: SessionProperties;
    setSession: Dispatch<SetStateAction<SessionProperties>>;
    updateSessionParameter: () => void;
    currentRun: string|null;
    setCurrentRun: Dispatch<SetStateAction<string|null>>;
    padding: boolean;
    setPadding: Dispatch<SetStateAction<boolean>>;
    startVideoBackground: () => void;
    stopVideoBackground: () => void;
}

export const AppContext: Context<AppProperties> = createContext<AppProperties>({
    openDrawer: () => undefined,
    closeDrawer: () => undefined,
    openErrorToast: () => undefined,
    closeErrorToast: () => undefined,
    session: {user: null, character: null, runs: []},
    setSession: () => undefined,
    updateSessionParameter: () => undefined,
    currentRun: null,
    setCurrentRun: () => undefined,
    padding: true,
    setPadding: () => undefined,
    startVideoBackground: () => undefined,
    stopVideoBackground: () => undefined

});

export const App: FunctionComponent = (): JSX.Element => {
    const classes: ClassNameMap = useStyles();
    const [sessionParameter, setSessionParameter] = useState<SessionParameter>(getSession(document.cookie));
    const [session, setSession] = useState<SessionProperties>({user: null, character: null, runs: []});
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [showMenuButton, setShowMenuButton] = useState<boolean>(false);
    const [drawerPosition, setDrawerPosition] = useState<'bottom'|'left'|'right'>('bottom');
    const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
    const [currentRun, setCurrentRun] = useState<string|null>(null);
    const [padding, setPadding] = useState<boolean>(true);
    const videoRef: MutableRefObject<HTMLVideoElement|undefined> = useRef<HTMLVideoElement>();
    const drawerContent: MutableRefObject<ReactNode> = useRef<ReactNode>();
    const errorContent: MutableRefObject<ReactNode> = useRef<ReactNode>();
    const errorCloseCallback: MutableRefObject<(() => void)|undefined> = useRef<() => void>();
    const drawerCloseCallback: MutableRefObject<(() => void)|undefined> = useRef<() => void>();

    const fetchSessionData = useCallback(async () => {
        try {
            let user: User|null = null;
            let character: Character|null = null;
            let runs: IRun[] = [];
            if (sessionParameter?.user) user = await API.getUser();
            if (sessionParameter?.character) character = await API.getCharacter(sessionParameter.character);
            if (sessionParameter?.runs) runs = await API.getRuns();
            setSession({user, character, runs});
            setShowMenuButton(!!user || !!character);
        } catch (error) {
            console.log(error);
        }
    }, [sessionParameter.character, sessionParameter?.runs, sessionParameter?.user]);

    function updateSessionParameter (): void {
        setSessionParameter(getSession(document.cookie));
    }

    function openDrawer (content: ReactNode, position?: 'bottom'|'left'|'right'): void {
        drawerContent.current = content;
        setShowDrawer(true);
        if (position && position !== drawerPosition) setDrawerPosition(position);
    }

    function closeDrawer (callback?: () => void): void {
        drawerContent.current = <span></span>;
        setShowDrawer(false);
        if (callback) drawerCloseCallback.current = callback;
        else drawerCloseCallback.current = undefined;
    }

    function openErrorToast (message: ReactNode, onErrorClose?: () => void): void {
        errorContent.current = message;
        errorCloseCallback.current = onErrorClose;
        setShowErrorToast(true);
    }

    function closeErrorToast (): void {
        setShowErrorToast(false);
        if (errorCloseCallback.current) errorCloseCallback.current.apply(null);
    }

    function startVideoBackground (): void {
        videoRef.current?.play();
    }

    function stopVideoBackground (): void {
        videoRef.current?.pause();
    }

    function handleMenuClick (): void {
        openDrawer(<MainMenu />, 'left');
    }

    useEffect(() => {
        fetchSessionData();
    }, [fetchSessionData]);

    useEffect(() => {
        setSessionParameter(getSession(document.cookie));
    }, []);

    useEffect(() => {
        if (!showDrawer && drawerCloseCallback.current) {
            drawerCloseCallback.current();
        }
    }, [showDrawer]);

    return (<Router>
        <LocalizationProvider value={{properties: {}}}>
            <ThemeProvider theme={theme}>
                <AppContext.Provider value={{openDrawer, closeDrawer, openErrorToast, closeErrorToast, session, setSession, updateSessionParameter, currentRun, setCurrentRun, padding, setPadding, startVideoBackground, stopVideoBackground}}>
                    <VideoBackground sources={[{src: '/video/star_background.webm', type: 'video/webm'}]} ref={videoRef as MutableRefObject<HTMLVideoElement>}>
                        {showMenuButton ? <Fab color="primary" size="small" aria-label="Menu" style={{margin: '1rem'}} onClick={handleMenuClick}>
                            <MenuIcon />
                        </Fab> : <div style={{height: '4rem'}}></div>}
                        <Container className={classes.root} disableGutters={false} maxWidth={padding ? 'lg': false}>
                                <Route path="/" exact={true} component={Start} />
                                <Route path="/run/:id" component={Run} />
                            <Drawer anchor={drawerPosition} open={showDrawer} onClose={() => closeDrawer()}>
                                {drawerContent.current}
                            </Drawer>
                            <Snackbar open={showErrorToast} onClose={closeErrorToast}>
                                <Grid container style={{height: '100%'}}>
                                    <Hidden mdDown>
                                        <Grid item md={4}>
                                            <video playsInline autoPlay muted loop controls={false} className={classes.video}>
                                                <source src="/video/goblin_crying.webm" />
                                            </video>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} md={8}>
                                        {errorContent.current}
                                    </Grid>
                                </Grid>
                            </Snackbar>
                        </Container>
                    </VideoBackground>
                </AppContext.Provider>
            </ThemeProvider>
        </LocalizationProvider>
    </Router>);
};