import { createTheme, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const colors: Record<string, string> = {
    deathKnight: '#C41F3B',
    druid: '#FF7D0A',
    hunter: '#ABD473',
    mage: '#69CCF0',
    paladin: '#F58CBA',
    priest: '#FFFFFF',
    rogue: '#FFF569',
    shaman: '#0070DE',
    warlock: '#9482C9',
    warrior: '#C79C6E',
    chat: '#FEC1C0',
    system: '#FFFF00',
    guild: '#3CE13F',
    officer: '#40BC40',
    party: '#AAABFE',
    partyLeader: '#77C8FF',
    spell : '#67BCFF',
    say: '#FFFFFF',
    whisper: '#FF7EFF',
    yell: '#FF3F40',
    emote: '#FF7E40',
    raid: '#FF7D01',
    raidLeader: '#FF4709',
    raidWarning: '#FF4700',
    achievement: '#FFFC01',
    battlenetWhisper: '#00FAF6',
    battlenetConversation: '#00ADEF',
    poor: '#889D9D',
    common: '#FFFFFF',
    uncommon: '#1EFF0C',
    rare: '#0070FF',
    epic: '#A335EE',
    legendary: '#FF8000',
    misc: '#FFD100'
};

export const scrollbar: CSSProperties = {
    '&::-webkit-scrollbar': {
        width: '.5rem'
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 .2rem ${translateHexRgba(colors.uncommon, .5)}`
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: translateHexRgba(colors.uncommon, .1),
        outline: 'none',
        borderRadius: '1rem'
    },
    'scrollbar-width': 'thin',
    'scrollbar-color': `${translateHexRgba(colors.uncommon, .1)} ${translateHexRgba(colors.uncommon, .5)}`
};

const defaultTheme: Theme = createTheme();

export const theme: Theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: colors.deathKnight
        },
        secondary: {
            main: colors.uncommon
        },
        background: {
            default: '#030303',
            paper: '#050505'
        }
    },
    overrides: {
        MuiTypography: {
            h2: {
                fontSize: '1.5rem',
                textShadow: '1px 1px .5rem #000, .5rem .5rem 1rem #000'
            },
            h3: {
                fontSize: '1.3rem',
                textShadow: '1px 1px .5rem #000, .5rem .5rem 1rem #000'
            },
            h4: {
                fontSize: '1.1rem',
                textShadow: '1px 1px .5rem #000, .5rem .5rem 1rem #000'
            }
        },
        MuiButton: {
            label: {
                textShadow: '1px 1px .5rem #000',
            },
            containedPrimary: {
                boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5), .2rem .2rem rgba(0, 0, 0, .5)',
                '&:hover': {
                    boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5), .2rem .2rem rgba(0, 0, 0, .5)'
                }
            }
        },
        MuiFab: {
            primary: {
                boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5), .2rem .2rem rgba(0, 0, 0, .5)',
                '&:hover': {
                    boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5), .2rem .2rem rgba(0, 0, 0, .5)'
                }
            }
        },
        MuiTextField: {
            root: {
                '& input': {
                    background: 'rgba(0, 0, 0, .5) !important',
                    boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5)',
                    padding: '.8rem',
                    '&:-webkit-autofill': {
                        background: 'rgba(0, 0, 0, .5) !important',
                        boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5) !important',
                        caretColor: 'transparent !important'
                    },
                    '&:-internal-autofill-selected': {
                        background: 'rgba(0, 0, 0, .5) !important',
                        boxShadow: 'inset .2rem .2rem 1rem rgba(0, 0, 0, .5) !important',
                        caretColor: 'transparent !important'
                    }
                },
                '& fieldset': {
                    '& span': {
                    }
                }
            }
        },
        MuiAvatar: {
            root: {
                height: '56px',
                width: '56px'
            },
            circle: {
                border: `1px solid ${colors.chat}`,
                boxShadow: '.2rem .2rem 1rem #000'
            }
        },
        MuiListItem: {
            root: {
                background: 'rgba(0, 0, 0, .3)',
                borderTop: '1px solid #fff',
                cursor: 'pointer',
                '&:first-child': {
                    border: 'none'
                },
                '&:hover': {
                    background: 'rgba(0, 0, 0, .5)'
                },
                '&$selected': {
                    background: 'rgba(0, 0, 0, .7)',
                    '&:hover': {
                        background: 'rgba(0, 0, 0, .9)'
                    }
                }
            }
        },
        MuiListItemAvatar: {
            root: {
                marginRight: '1.5rem'
            }
        },
        MuiSnackbar: {
            anchorOriginBottomCenter: {
                height: '20%',
                borderRadius: '1rem',
                background: 'rgba(0, 0, 0, .3)',
                bottom: '-1rem !important'
            }
        },
        MuiDrawer: {
            paperAnchorLeft: {
                background: 'rgba(0, 0, 0, .7)',
                [defaultTheme.breakpoints.up('sm')]: {
                    maxWidth: '25%'
                },
                ...scrollbar
            }
        },
        MuiPaper: {
            root: {
                ...scrollbar
            }
        }
    }
});

export function translateHexRgba (color: string, alpha: number): string {
    if (typeof color !== 'string' || !color.startsWith('#') || color.length > 7) throw new TypeError();
    if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) throw new TypeError();
    const red: number = parseInt(color.substr(1,2), 16);
    const green: number = parseInt(color.substr(3,2), 16);
    const blue: number = parseInt(color.substr(5,2), 16);
    if (isNaN(red) || isNaN(green) || isNaN(blue)) throw new SyntaxError();
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}