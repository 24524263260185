import { Avatar, capitalize, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { CharacterListItemProperties } from './CharacterListItem';


export const SmallCharacterListItem: FunctionComponent<CharacterListItemProperties> = ({character, selected, onClick, children}: CharacterListItemProperties): JSX.Element => {
    return <ListItem onClick={() => onClick(character)} selected={selected} style={{background: selected ? 'rgba(255, 255, 255, .1)' : 'transparent'}}>
        <ListItemAvatar style={{minWidth: '2rem'}}>
            <Avatar alt={character.class} src={`/icons/class_${character.class}.jpg`} style={{width: '2rem', height: '2rem'}} />
        </ListItemAvatar>
        <ListItemText primary={`${character.name}, ${capitalize(character.race)} ${capitalize(character.role)}`} secondary={children}></ListItemText>
    </ListItem>;
};