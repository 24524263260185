import { Authentication, Credentials, HttpError, HttpHeader, HttpMethod, MimeType } from '@sml86/httpjs';
import { Character, CreateCharacterBody, Role } from 'shared/Character';
import { ANONYMOUS_USER_NAME } from 'shared/Constants';
import { Item } from 'shared/Item';
import { CreateRunBody, Run } from 'shared/Run';
import { User } from 'shared/User';

const base: string = '/api';

export class API {
    public static async check (): Promise<void> {
        const response: Response|void = await fetch(`${base}/`, {
            method: HttpMethod.HEAD,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
    }

    public static async login (username: string, password: string): Promise<User> {
        const response: Response|void = await fetch (`${base}/user/login`, {
            headers: {
                [HttpHeader.AUTHORIZATION]: Authentication.getBasicHeader(username, password)
            },
            method: HttpMethod.POST
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async getUser (): Promise<User> {
        const response: Response|void = await fetch (`${base}/user`, {
            method: HttpMethod.GET,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async getCharacter (id: string): Promise<Character> {
        const response: Response|void = await fetch(`${base}/character/${id}`);
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async findCharacter (name: string): Promise<Character[]> {
        const response: Response|void = await fetch(`${base}/character/find/${name}`);
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async selectCharacter (id: string): Promise<Character> {
        const response: Response|void = await fetch(`${base}/character/${id}`, {
            method: HttpMethod.POST,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async createCharacter (body: CreateCharacterBody, behalf?: boolean): Promise<Character> {
        const response: Response|void = await fetch(`${base}/character${behalf ? '?behalf=true' : ''}`, {
            method: HttpMethod.POST,
            credentials: 'include',
            headers: {
                [HttpHeader.CONTENT_TYPE]: MimeType.JSON
            },
            body: JSON.stringify(body)
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async claimCharacter (id: string): Promise<Character> {
        const response: Response|void = await fetch(`${base}/character/claim/${id}`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async deleteSelectedCharacter (): Promise<void> {
        const response: Response|void = await fetch(`${base}/character`, {
            method: HttpMethod.DELETE,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return;
    }

    public static async updateCharacter (id: string, level: number, role: Role): Promise<Character> {
        const response: Response|void = await fetch(`${base}/character/${id}`, {
            method: HttpMethod.PUT,
            body: JSON.stringify({level, role}),
            headers: {[HttpHeader.CONTENT_TYPE]: MimeType.JSON},
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async getRun (id: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}`, {
            method: HttpMethod.GET,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async getRuns (): Promise<Run[]> {
        const response: Response|void = await fetch(`${base}/run`, {
            method: HttpMethod.GET,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const runs: Run[] = await response.json();
        for (const run of runs) {
            run.created = new Date(run.created);
            run.start = new Date(run.start);
            run.ended = run.ended ? new Date(run.ended) : null;
        }
        return runs;
    }

    public static async createRun (body: CreateRunBody): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run`, {
            method: HttpMethod.POST,
            body: JSON.stringify(body),
            headers: {[HttpHeader.CONTENT_TYPE]: MimeType.JSON},
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async joinRun (id: string, char: string, credentials?: Credentials): Promise<Run> {
        const headers: HeadersInit = {};
        if (credentials) {
            headers[HttpHeader.AUTHORIZATION] = Authentication.getBasicHeader(credentials.username, credentials.password);
        }
        const response: Response|void = await fetch(`${base}/run/${id}/character/${char}`, {
            method: HttpMethod.PUT,
            credentials: 'include',
            headers
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async confirmCharacterOnRun (id: string, char: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/confirm/${char}`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async unconfirmCharacterOnRun (id: string, char: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/unconfirm/${char}`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async lockRun (id: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/lock`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async unlockRun (id: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/unlock`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async endRun (id: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/end`, {
            method: HttpMethod.PUT,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async leaveRun (id: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/character`, {
            method: HttpMethod.DELETE,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async authorizeRun (id: string, password: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/authorize`, {
            method: HttpMethod.POST,
            credentials: 'include',
            headers: {[HttpHeader.AUTHORIZATION]: Authentication.getBasicHeader(ANONYMOUS_USER_NAME, password)}
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async deleteCharacterOnRun (id: string, char: string): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/character/${char}`, {
            method: HttpMethod.DELETE,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async addPrioItem (id: string, char: string, item: number, priority: number): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/character/${char}/item`, {
            method: HttpMethod.PUT,
            credentials: 'include',
            headers: {[HttpHeader.CONTENT_TYPE]: MimeType.JSON},
            body: JSON.stringify({item, priority})
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async removePrioItem (id: string, char: string, item: number): Promise<Run> {
        const response: Response|void = await fetch(`${base}/run/${id}/character/${char}/item/${item}`, {
            method: HttpMethod.DELETE,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        const run: Run = await response.json();
        run.created = new Date(run.created);
        run.start = new Date(run.start);
        run.ended = run.ended ? new Date(run.ended) : null;
        return run;
    }

    public static async getItem (id: number): Promise<Item> {
        const response: Response|void = await fetch(`${base}/item/${id}`, {
            method: HttpMethod.GET,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }

    public static async getItems (zone: number): Promise<Item[]> {
        const response: Response|void = await fetch(`${base}/item/zone/${zone}`, {
            method: HttpMethod.GET,
            credentials: 'include'
        });
        if (!response.ok) throw new HttpError(response.status, response.statusText);
        return response.json();
    }
}