import { FunctionComponent, MutableRefObject, ReactNode } from 'react';
import { styles } from './VideoBackground.style';

export interface VideoProperties {
    sources: VideoSource[];
    children?: ReactNode;
    className?: string;
    ref?: MutableRefObject<HTMLVideoElement>;
}

export type VideoSource = {src: string, type: string};

export const VideoBackground: FunctionComponent<VideoProperties> = (props: VideoProperties): JSX.Element => {
    const classes = styles();
    return (
        <div className={`${classes.root} ${props.className}`}>
            <video playsInline autoPlay muted loop controls={false} className={classes.video} ref={props.ref}>
                {props.sources.map((source: VideoSource, index: number) => <source key={`video-${index}`} src={source.src} type={source.type} />)}
            </video>
            {props.children && <div className={classes.overlay}>{props.children}</div>}
        </div>
    );
};