import { Avatar, Button, FormControl, FormControlLabel, FormHelperText, Grid, ListItemAvatar, ListItemText, MenuItem, Switch, TextField ,Typography } from '@material-ui/core';
import { Credentials, HttpError, HttpStatus } from '@sml86/httpjs';
import { ChangeEvent, FormEvent, FunctionComponent, MutableRefObject, ReactNode, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dungeonLootList, raidIconMap, raids } from 'shared/Dungeon';
import { Realm, realmList } from 'shared/Realm';
import { Run } from 'shared/Run';
import { API } from '../api/API';
import { AppContext } from '../App';
import { CalendarPicker } from '../common/CalendarPicker';
import { useStyles } from './RunStyles';

export const CreateRun: FunctionComponent = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const { closeDrawer, openErrorToast, session, updateSessionParameter } = useContext(AppContext);
    const dungeonRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const realmRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const prioritiesRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const pinRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [start, setStart] = useState<Date>(new Date());

    async function handleFormSubmit (event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            if (dungeonRef.current?.value && realmRef.current?.value && prioritiesRef.current?.value) {
                if (isPrivate && !pinRef.current?.value) throw new Error('PIN');
                const dungeon: number = parseInt(dungeonRef.current.value, 10);
                const priorities: number = parseInt(prioritiesRef.current.value, 10);
                const run: Run = await API.createRun({
                    dungeon, priorities,
                    server: realmRef.current.value,
                    private: isPrivate,
                    pin: pinRef.current?.value,
                    start
                });
                if (session.character && session.character.server === run.server) {
                    const credentials: Credentials|undefined = run.private ? {username: session.character.name, password: pinRef.current?.value || ''} : undefined;
                    await API.joinRun(run.id, session.character.id, credentials);
                }
                updateSessionParameter();
                history.push(`/run/${run.id}`);
                closeDrawer();
            }
        } catch (exception) {
            const error: HttpError = exception as HttpError;
            let message: ReactNode = <Typography variant="h2" component="span">{error.message}</Typography>;
            if (error.message === 'PIN') {
                message = <div>
                    <p><Typography variant="h2" component="span">How shall that work!</Typography></p>
                    <p><Typography variant="subtitle1" component="span">You have to provide a pin for private runs.<br />How else shall your mates join?</Typography></p>
                </div>;
            } else if (error.status === HttpStatus.PreconditionFailed) {
                message = <div>
                    <p><Typography variant="h2" component="span">Well...</Typography></p>
                    <p><Typography variant="subtitle1" component="span">Your run was created successfully... but your selected character is not on this server...</Typography></p>
                </div>;
            }
            else closeDrawer();
            openErrorToast(message);
        }
    }

    return <Grid container style={{padding: '2rem'}}>
        <form onSubmit={handleFormSubmit}>
            <Grid item xs={12} className={classes.lineMargin}>
                <Typography variant="h2">Create New Run</Typography>
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <TextField select color="secondary" label={'Dungeon'} variant="outlined" fullWidth defaultValue="" inputRef={dungeonRef}>
                    {raids.map((id: number) => <MenuItem key={`raid-dungeon-${id}`} value={id}>
                        <ListItemAvatar>
                            <Avatar src={`/icons/${raidIconMap[id]}.jpg`} alt={dungeonLootList[id].name} />
                        </ListItemAvatar>
                        <ListItemText primary={dungeonLootList[id].name} />
                    </MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <CalendarPicker start={start} onChange={(date) => setStart(date)} />
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <TextField color="secondary" select label={'Realm'} variant="outlined" fullWidth defaultValue={session.character?.server} inputRef={realmRef}>
                    {realmList.map((realm: Realm) => <MenuItem key={`realm-${realm.id}`} value={realm.slug} selected={session.character?.server === realm.slug}>{realm.name}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin}>
                <TextField type="number" size="small" color="secondary" label={'Priorities'} helperText={'Priority Items per Character'} variant="outlined" defaultValue="0" inputProps={{min: '0'}} inputRef={prioritiesRef} />
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin}>
                <FormControl>
                    <FormControlLabel control={<Switch color="secondary" onChange={(e: ChangeEvent<HTMLInputElement>) => setIsPrivate(!!e.target?.checked)} />} label={'Private'} />
                    <FormHelperText>This Run requires a Pin</FormHelperText>
                </FormControl>
            </Grid>
            {!!isPrivate && <Grid container item xs={12} className={classes.lineMargin}>
                <TextField color="secondary" size="small" variant="outlined" label={'Pin'} inputRef={pinRef} />
            </Grid>}
            <Grid item xs={12} className={classes.lineMargin} justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" style={{flex: '0 0 auto'}}>Create</Button>
            </Grid>
        </form>
    </Grid>;
};