import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%'
    },
    video: {
        width: '60%',
        objectPosition: 'center',
        objectFit: 'cover'
    },
    lineMargin: {
        marginBottom: '1rem'
    },
    pinDrawer: {
        background: 'rgba(0, 0, 0, .2)',
        padding: '2rem'
    },
    midAvatar: {
        width: '2rem',
        height: '2rem'
    },
    memberPrioList: {
        display: 'flex',
        flexDirection: 'row',
        padding: '.5rem 0 0',
        '&>li': {
            width: '2rem',
            padding: '0'
        }
    },
    datePicker: {
        background: 'transparent'
    }
}));