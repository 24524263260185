import { Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText, TextField } from '@material-ui/core';
import { CSSProperties, FormEvent, FunctionComponent, MutableRefObject, useCallback, useContext, useRef } from 'react';
import { Character } from 'shared/Character';
import { API } from '../api/API';
import { AppContext } from '../App';
import { CharacterListItem } from './CharacterListItem';
import { useStyles } from './CharacterStyles';
import { ScrollableCharacterList } from './ScrollableCharacterList';

export interface CharacterSearchProperties {
    onSearchItemClick: (character: Character) => void;
    onNewCharacterClick: (name: string) => void;
    onSearchSubmit?: (value: string, callback: (value: string) => Promise<void>) => void;
    defaultSearchValue?: string;
    style?: CSSProperties;
}

export const CharacterSearch: FunctionComponent<CharacterSearchProperties> = ({onSearchItemClick, onNewCharacterClick, onSearchSubmit, defaultSearchValue, style}: CharacterSearchProperties): JSX.Element => {
    const classes = useStyles();
    const { openDrawer } = useContext(AppContext);
    const characterNameRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();

    const updateSearchlist = useCallback(async (value: string) => {
        function handleSearchItemClick (character: Character): void {
            if (characterNameRef.current) {
                characterNameRef.current.value = '';
                characterNameRef.current.blur();
            }
            onSearchItemClick(character);
        }

        function handleNewCharacterClick (name: string) {
            if (characterNameRef.current) {
                characterNameRef.current.value = '';
                characterNameRef.current.blur();
            }
            onNewCharacterClick(name);
        }

        const foundCharacters: Character[] = await API.findCharacter(value);
        openDrawer(<ScrollableCharacterList>
            {foundCharacters.map((char: Character) => <CharacterListItem key={char.id} character={char} selected={false} onClick={() => handleSearchItemClick(char)} />)}
            <ListItem onClick={() => handleNewCharacterClick(characterNameRef?.current?.value || '')}>
                <ListItemAvatar>
                    <Avatar src="/icons/inv_mask_03.jpg" alt="Create new character"/>
                </ListItemAvatar>
                <ListItemText>Create a new character</ListItemText>
            </ListItem>
        </ScrollableCharacterList>, 'bottom');
    }, [onNewCharacterClick, onSearchItemClick, openDrawer]);

    async function handleFormSubmit (event: FormEvent) {
        event.preventDefault();

        if (characterNameRef?.current?.value && characterNameRef.current.value.length >= 3) {
            if (onSearchSubmit) onSearchSubmit.call(null, characterNameRef.current.value, updateSearchlist);
            else updateSearchlist(characterNameRef.current.value);
        }
    }

    // useEffect(() => {
    //     if (defaultSearchValue) updateSearchlist(defaultSearchValue);
    // }, [defaultSearchValue, updateSearchlist]);

    return (<form className={classes.searchForm} onSubmit={handleFormSubmit} style={style}>
        <Grid container spacing={0} item xs={12} justifyContent="center">
            <Grid item>
                <TextField color="secondary" label={'Character Name'} variant="outlined" inputRef={characterNameRef} defaultValue={defaultSearchValue} />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" type="submit" style={{margin: '.2rem'}}>Find</Button>
            </Grid>
        </Grid>
    </form>);
};