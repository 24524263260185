import { Authentication } from '@sml86/httpjs';
import { Character } from 'shared/Character';
import { SESSION_COOKIE_NAME } from 'shared/Constants';
import { Run } from 'shared/Run';
import { Session as SessionParameter } from 'shared/Session';
import { Token } from 'shared/Token';
import { User } from 'shared/User';

export interface SessionProperties {
    user: User|null;
    character: Character|null;
    runs: Run[];
}

export type Runs = {[id: string]: Run};

export function getSession (cookies: string): SessionParameter {
    // eslint-disable-next-line no-useless-escape
    const pattern: RegExp = new RegExp(`(?:(?:^|.*;\s*)${SESSION_COOKIE_NAME}\s*\=\s*([^;]*).*$)|^.*$`);
    const cookie: string = cookies.replace(pattern, '$1');
    if (cookie) {
        const token: Token = Authentication.decodeBearerToken(cookie)?.payload as Token;
        return token.ses;
    }
    return {
        user: null,
        anonymous: true,
        character: null,
        runs: {}
    };
}

export function endSession (): SessionParameter {
    document.cookie = `${SESSION_COOKIE_NAME}=; path=/`;
    return {
        user: null,
        anonymous: true,
        character: null,
        runs: {}
    };
}