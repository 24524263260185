import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors, translateHexRgba } from './Theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: '.5rem'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 .2rem ${translateHexRgba(colors.uncommon, .5)}`
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: translateHexRgba(colors.uncommon, .1),
            outline: 'none',
            borderRadius: '1rem'
        },
        [theme.breakpoints.down('lg')]: {
            overflowY: 'auto',
        }
    },
    video: {
        width: '100%',
        objectPosition: 'center',
        objectFit: 'cover',
        position: 'relative',
        top: '-30%',
        left: '0'
    },
    menuList: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '480px'
        }
    },
    menuListItem: {
        borderTop: `1px solid ${colors.poor}`,
        color: theme.palette.text.primary,
        '&:hover': {
            background: `rgba(100, 100, 100, .3)`,
            color: theme.palette.text.primary
        },
        '&:active': {
            color: theme.palette.text.primary
        },
        '&:visited': {
            color: theme.palette.text.primary
        },
        '&:-webkit-link': {
            color: theme.palette.text.primary
        }
    },
    menuListItemIcon: {
        width: '3rem',
        minWidth: '3rem',
        height: '3rem',
        marginRight: '1rem',
        borderRadius: '1.5rem',
        border: `1px solid ${colors.poor}`,
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: '50%'
        }
    }
}));