import { Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FormEvent, FunctionComponent, MutableRefObject, ReactNode, useContext, useRef, useState } from 'react';
import { Character, CharacterClass, classRoleMap, CreateCharacterBody, Faction, Gender, Race, raceClassMap, Role, roleIconMap } from 'shared/Character';
import { MAX_LEVEL } from 'shared/Constants';
import { Realm, realmList } from 'shared/Realm';
import { API } from '../api/API';
import { AppContext } from '../App';
import { useStyles } from './CharacterStyles';

export interface CreateCharacterProperties {
    name: string;
    onErrorClose: (searchValue: string) => void;
    onSuccess?: (character: Character) => void;
    behalf?: boolean;
    realm?: string;
}

export const CreateCharacter: FunctionComponent<CreateCharacterProperties> = ({ name, onErrorClose, onSuccess, behalf, realm }: CreateCharacterProperties): JSX.Element => {
    const classes = useStyles();
    const { closeDrawer, updateSessionParameter, openErrorToast } = useContext(AppContext);
    const nameInputRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const realmInputRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const levelInputRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const [selectedRace, setSelectedRace] = useState<Race>();
    const [selectedGender, setSelectedGender] = useState<Gender>();
    const [selectedClass, setSelectedClass] = useState<CharacterClass>();
    const [selectedRole, setSelectedRole] = useState<Role>();
    const [availableClasses, setAvailableClasses] = useState<CharacterClass[]>([]);
    const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
    const horde: string[] = Object.keys(raceClassMap[Faction.horde]);
    const alliance: string[] = Object.keys(raceClassMap[Faction.alliance]);

    function handleRaceIconClick (faction: Faction, race: Race, gender: Gender): void {
        setSelectedRace(race);
        setSelectedGender(gender);
        setSelectedClass(undefined);
        setSelectedRole(undefined);
        setAvailableClasses(raceClassMap[faction][race]);
        setAvailableRoles([]);
    }

    function handleClassIconClick (className: CharacterClass): void {
        setSelectedClass(className);
        setSelectedRole(undefined);
        setAvailableRoles(classRoleMap[className]);
    }

    async function handleErrorClose (value?: string): Promise<void> {
        if (value) onErrorClose(value);
    }

    async function handleFormSubmit (event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            if (nameInputRef.current?.value && realmInputRef.current?.value && levelInputRef.current?.value && selectedRace && selectedGender && selectedClass && selectedRole) {
                const body: CreateCharacterBody = {
                    name: nameInputRef.current.value,
                    server: realmInputRef.current.value,
                    level: parseInt(levelInputRef.current.value, 10),
                    race: selectedRace,
                    gender: selectedGender,
                    class: selectedClass,
                    role: selectedRole
                };
                const char: Character = await API.createCharacter(body, !!behalf);
                if (typeof onSuccess === 'function') onSuccess(char);
                closeDrawer();
                updateSessionParameter();
            }
        } catch (exception) {
            const error = exception as HttpError;
            const value: string|undefined = nameInputRef.current?.value;
            closeDrawer();
            let message: ReactNode = <Typography variant="h2" component="span">{error.message}</Typography>;
            switch (error.status) {
                case HttpStatus.Conflict: message = <div>
                        <p><Typography variant="h2" component="span">Oh no!</Typography></p>
                        <p><Typography variant="subtitle1" component="span">The character you created, does already exist!<br />Try to search for it again.</Typography></p>
                    </div>; break;
            }
            openErrorToast(message, handleErrorClose.bind(null, value));
        }
    }

    return (<Grid container style={{padding: '2rem'}}>
        <form onSubmit={handleFormSubmit}>
            <Grid item xs={12} className={classes.lineMargin}>
                <Typography variant="h2">Create Character</Typography>
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <TextField color="secondary" label={'Name'} variant="outlined" fullWidth inputRef={nameInputRef} defaultValue={name} />
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <TextField color="secondary" select label={'Realm'} variant="outlined" fullWidth inputRef={realmInputRef} defaultValue={realm}>
                    {realmList.map((realm: Realm) => <MenuItem key={`realm-${realm.id}`} value={realm.slug}>{realm.name}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={12} className={classes.lineMargin}>
                <TextField type="number" size="small" color="secondary" label={'Level'} variant="outlined" inputRef={levelInputRef} style={{width: '5rem'}} inputProps={{min: '1', max: MAX_LEVEL}} />
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin}>
                <Grid item xs={12} className={classes.lineMargin}><Typography variant="h3">Race and Gender</Typography></Grid>
                <Grid container item xs={12} sm={6} spacing={2} className={classes.allianceBackground}>
                    <Grid item xs={12}>
                        <img src="/icons/inv_bannerpvp_02.jpg" alt="Join the Alliance" className={classes.banner} />
                    </Grid>
                    {alliance.map((race: string) => <>
                        <Grid key={`ally-female-${race}`} item>
                            <img src={`/icons/achievement_character_${race}_female.jpg`} alt={`${race} female`} className={`${selectedRace === race && selectedGender === 'female' ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => handleRaceIconClick(Faction.alliance, race as Race, 'female')} />
                        </Grid>
                        <Grid key={`ally-male-${race}`} item>
                            <img src={`/icons/achievement_character_${race}_male.jpg`} alt={`${race} male`} className={`${selectedRace === race && selectedGender === 'male' ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => handleRaceIconClick(Faction.alliance, race as Race, 'male')} />
                        </Grid>
                    </>)}
                </Grid>
                <Grid container item xs={12} sm={6} spacing={2} className={classes.hordeBackground}>
                    <Grid item xs={12}>
                        <img src="/icons/inv_bannerpvp_01.jpg" alt="Join the Horde" className={classes.banner} />
                    </Grid>
                    {horde.map((race: string) => <>
                        <Grid key={`horde-female-${race}`} item>
                            <img src={`/icons/achievement_character_${race}_female.jpg`} alt={`${race} female`} className={`${selectedRace === race && selectedGender === 'female' ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => handleRaceIconClick(Faction.horde, race as Race, 'female')} />
                        </Grid>
                        <Grid key={`horde-male-${race}`} item>
                            <img src={`/icons/achievement_character_${race}_male.jpg`} alt={`${race} male`} className={`${selectedRace === race && selectedGender === 'male' ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => handleRaceIconClick(Faction.horde, race as Race, 'male')} />
                        </Grid>
                    </>)}
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin}>
                <Grid item xs={12} className={classes.lineMargin}><Typography variant="h3">Class</Typography></Grid>
                <Grid container item xs={12} spacing={2}>
                    {availableClasses.map((className: string) => <Grid key={`char-class-${className}`} item>
                        <img src={`/icons/class_${className}.jpg`} alt={className} className={`${selectedClass === className ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => handleClassIconClick(className as CharacterClass)} />
                    </Grid>)}
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin}>
                <Grid item xs={12} className={classes.lineMargin}><Typography variant="h3">Role</Typography></Grid>
                <Grid container item xs={12} spacing={2}>
                    {availableRoles.map((role: string) => <Grid key={`char-role-${role}`} item>
                        <img src={`/icons/${roleIconMap[role]}`} alt={role} className={`${selectedRole === role ? `${classes.banner} ${classes.selected}` : classes.banner}`} onClick={() => setSelectedRole(role as Role)} />
                    </Grid>)}
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.lineMargin} justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" style={{flex: '0 0 auto'}}>Create</Button>
            </Grid>
        </form>
    </Grid>);
};