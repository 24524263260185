import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { FunctionComponent, useContext } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Run } from 'shared/Run';
import { API } from '../api/API';
import { AppContext } from '../App';
import { useStyles } from '../AppStyles';
import { UpdateCharacter } from '../character/UpdateCharacter';
import { RunListItem } from '../run/RunListItem';
import { endSession } from '../session/Session';

export const MainMenu: FunctionComponent = (): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { session, updateSessionParameter, closeDrawer } = useContext(AppContext);

    async function handleUnselectCharacterClick (): Promise<void> {
        try {
            await API.deleteSelectedCharacter();
            updateSessionParameter();
            closeDrawer();
        } catch (error) {
            console.error(error);
        }
    }

    function handleLogOffClick (): void {
        endSession();
        updateSessionParameter();
    }

    function handleRunClick (run: string): void {
        history.push(`/run/${run}`);
        closeDrawer();
    }

    return <Grid container justifyContent="space-between" alignItems="stretch" alignContent="stretch" style={{height: '100%'}}>
        <Grid item xs={12}>
            <List className={classes.menuList}>
                {!!session.character && location.pathname === '/' && <ListItem className={classes.menuListItem} onClick={handleUnselectCharacterClick}>
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <img src="/icons/ability_mage_invisibility.jpg" alt="log off" />
                    </ListItemIcon>
                    <ListItemText>Unselect character</ListItemText>
                </ListItem>}
                {!!location.pathname.match('run') && <ListItem className={classes.menuListItem} component={NavLink} to="/" role="button" onClick={() => closeDrawer()}>
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <img src="/icons/inv_misc_rune_01.jpg" alt="home" />
                    </ListItemIcon>
                    <ListItemText>Back to home</ListItemText>
                </ListItem>}
                {!!session.user && <ListItem className={classes.menuListItem} onClick={handleLogOffClick}>
                    <ListItemIcon className={classes.menuListItemIcon}>
                        <img src="/icons/ability_stealth.jpg" alt="log off" />
                    </ListItemIcon>
                    <ListItemText>Log off</ListItemText>
                </ListItem>}
            </List>
        </Grid>
        <Grid item xs={12}>
            {!!session.character && <UpdateCharacter character={session.character} />}
            {!!session.runs.length && <div style={{padding: '1rem'}}>
                <Typography variant="h3">Current Runs</Typography>
                <List>
                    {session.runs.map((run: Run) => <RunListItem key={`run-item-${run.id}`} run={run} onClick={() => handleRunClick(run.id)} />)}
                </List>
            </div>}
        </Grid>
    </Grid>;
};