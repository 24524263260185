import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    shadow: {
        boxShadow: '.5rem .5rem rgba(0, 0, 0, .5)',
        borderRadius: '.2rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '1rem 3rem',
        height: 'calc(100% - 2rem)'
    },
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '1rem',
        width: '100%',
        '&>.MuiGrid-item': {
            marginBottom: '1rem'
        }
    },
    characterNotFound: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        marginBottom: '1rem'
    },
    video: {
        width: '80%',
        objectPosition: 'center',
        objectFit: 'contain'
    },
    characterList: {
        width: '100%',
        height: 'calc(100% - 82px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '.5rem'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, .5)',
            outline: 'none',
            borderRadius: '1rem'
        }
    },
    smallIcon: {
        display: 'inline-flex',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '.3rem',
        marginRight: '1rem'
    }
}));