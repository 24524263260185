import { Avatar, createStyles, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FunctionComponent, MouseEvent, useState } from 'react';
import { Item } from 'shared/Item';
import { colors } from '../Theme';
import { ItemTooltip } from './ItemTooltip';

export const useStyles = makeStyles(() => createStyles({
    small: {
        width: '2rem',
        height: '2rem',
        border: '1px solid'
    }
}));

export const ItemListItem: FunctionComponent<{item: Item, onClick?: (item: Item) => void, onActionClick?: (item: Item) => void, small?: boolean, action?: boolean}> = ({item, onClick, onActionClick, small, action}): JSX.Element => {
    const classes = useStyles();
    const [tooltip, setTooltip] = useState<JSX.Element>();

    function handleMouseOver (event: MouseEvent): void {
        setTooltip(<ItemTooltip item={item} mouseX={event.clientX} mouseY={event.clientY} />);
    }

    function handleListItemClick (): void {
        if (onClick) onClick.apply(null, [item]);
    }

    function handleActionClick (): void {
        if (onActionClick) onActionClick.apply(null, [item]);
    }

    return <ListItem key={`item-${item.itemId}`} onClick={handleListItemClick}>
        <ListItemAvatar>
            <Avatar
                src={`/icons/${item.icon}.jpg`}
                alt={item.name} variant="rounded"
                onMouseOver={handleMouseOver}
                onMouseOut={() => setTooltip(undefined)}
                className={small ? classes.small : undefined}
                style={{borderColor: small ? colors[item.quality.toLowerCase()] : undefined}}
            />
        </ListItemAvatar>
        {!small && <ListItemText style={{color: colors[item.quality.toLowerCase()]}} primary={item.name} secondary={item.source.name} />}
        {!small && action && <ListItemSecondaryAction>
            <IconButton edge="end" onClick={handleActionClick}><Delete /></IconButton>
        </ListItemSecondaryAction>}
        {tooltip}
    </ListItem>;
};