import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { dungeonLootList, raidIconMap } from 'shared/Dungeon';
import { Run } from 'shared/Run';
import { useStyles } from '../start/StartStyles';

export interface RunListItemProperties {
    run: Run;
    onClick: (id: string) => void;
}

export const RunListItem: FunctionComponent<RunListItemProperties> = ({run, onClick}): JSX.Element => {
    const classes = useStyles();

    return <ListItem onClick={() => onClick(run.id)}>
        <ListItemAvatar>
            <Avatar src={`/icons/${raidIconMap[run.dungeon]}.jpg`} alt={`dungeon ${run.dungeon}`} />
        </ListItemAvatar>
        <ListItemText primary={dungeonLootList[run.dungeon].name} secondary={
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '.5rem'}}>
                {!!run.private && <img src="/icons/inv_misc_key_05.jpg" alt="private" className={classes.smallIcon} />}
                <span>{run.start.format('mmm dd, yyyy')}</span>
                {!!run.creator?.name && <>&nbsp; by &nbsp; <span>{run.creator.name}</span></>}
                &nbsp; on &nbsp; <span>&lt;{run.server}&gt;</span>

            </div>}></ListItemText>
    </ListItem>;
};