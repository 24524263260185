import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

const months: string[] = [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
'August', 'September', 'Oktober', 'November', 'Dezember'];

interface CalenderPickerProps {
    start: Date;
    onChange: (date: Date) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    },
    button: {
        minWidth: '2rem'
    },
    selected: {
        backgroundColor: theme.palette.primary.main
    },
    headline: {
        fontSize: '1.2rem'
    }
}));

export const CalendarPicker: FunctionComponent<CalenderPickerProps> = ({ start, onChange }): JSX.Element => {
    const classes = useStyles();
    const [current, setCurrent] = useState<Date>(start);
    const [selected, setSelected] = useState<number>((new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0)).getTime());
    const [dates, setDates] = useState<Date[][]>([]);

    useEffect(() => {
        let date: Date = new Date(current.getFullYear(), current.getMonth(), 1 - current.getDay(), 0, 0, 0, 0);
        const dates: Date[][] = [];
        for (let i = 0; i < 5; ++i) {
            const week: Date[] = [];
            for (let j = 0; j < 7; ++j) {
                week.push(new Date(date.getTime()));
                date = new Date(date.setDate(date.getDate()+1));
            }
            dates.push(week);
        }
        setDates(dates);
    }, [current]);

    function handleClickDate (date: Date): void {
        setSelected(date.getTime());
        onChange.apply(null, [date]);
    }

    function changeMonth (value: number): void {
        const date: Date = new Date(current.setMonth(current.getMonth() + value));
        setCurrent(date);
    }

    function renderDate (date: Date): ReactNode {
        return <Grid item xs={1} key={`date-${date.getTime()}`}>
            <Button size="small" variant="text" className={selected === date.getTime() ? `${classes.button} ${classes.selected}` : classes.button} onClick={() => handleClickDate(date)}>{date.getDate()}</Button>
        </Grid>;
    }

    function renderWeek (week: Date[]): ReactNode {
        const days: ReactNode[] = [];
        for (const date of week) {
            days.push(renderDate(date));
        }
        return <Grid container item xs={12} alignItems="center" justifyContent="center" key={`week-${week[0].getTime()}`}>{days}</Grid>;
    }

    return <Grid container className={classes.root}>
        <Grid container item xs={12}>
            <Grid container item xs={3} alignItems="center" justifyContent="center">
                <IconButton aria-label={'Month Back'} size="small" onClick={() => changeMonth(-1)}><ArrowBackIos /></IconButton>
            </Grid>
            <Grid container item xs={6} justifyContent="center" alignContent="center"><Typography variant="h5" className={classes.headline}>{months[current.getMonth()]} {current.getFullYear()}</Typography></Grid>
            <Grid container item xs={3} alignItems="center" justifyContent="center" alignContent="center">
                <IconButton aria-label={'Month Forward'} size="small" onClick={() => changeMonth(+1)}><ArrowForwardIos /></IconButton>
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            {dates.map((week: Date[]) => renderWeek(week))}
        </Grid>
    </Grid>;
};