import { Button, Grid, List, TextField, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { HttpStatus } from '@sml86/httpjs';
import { FormEvent, FunctionComponent, MutableRefObject, ReactNode, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Character } from 'shared/Character';
import { Run } from 'shared/Run';
import { API } from '../api/API';
import { Divider } from '../api/common/Divider';
import { AppContext } from '../App';
import { CharacterListItem } from '../character/CharacterListItem';
import { CharacterSearch } from '../character/CharacterSearch';
import { CreateCharacter } from '../character/CreateCharacter';
import { ScrollableCharacterList } from '../character/ScrollableCharacterList';
import { CreateRun } from '../run/CreateRun';
import { RunListItem } from '../run/RunListItem';
import { VideoBackground } from '../videobackground/VideoBackground';
import { useStyles } from './StartStyles';

export const Start: FunctionComponent = (): JSX.Element => {
    const classes: ClassNameMap = useStyles();
    const history = useHistory();
    const [defaultSearchValue, setDefaultSearchValue] = useState<string>();
    const usernameRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const passwordRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const runIdRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const { openDrawer, closeDrawer, openErrorToast, session, updateSessionParameter } = useContext(AppContext);

    async function handleLoginFormSubmit (event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            if (usernameRef?.current?.value && passwordRef?.current?.value) {
                await API.login(usernameRef.current.value, passwordRef.current.value);
                updateSessionParameter();
            }
        } catch (error) {
            let message: ReactNode = <Typography variant="h2" component="span">{error.message}</Typography>;
            switch (error.status) {
                case HttpStatus.NotFound: message = <div>
                        <p><Typography variant="h2" component="span">Ooooops!</Typography></p>
                        <p><Typography variant="subtitle1" component="span">It seems you typed in wrong credentials!?<br />You should check your credentials and try again!</Typography></p>
                    </div>; break;
            }
            openErrorToast(message);
        }
    }

    function handleRunItemClick (run: string): void {
        history.push(`/run/${run}`);
        closeDrawer();
    }

    async function handleJoinSubmit (event: FormEvent): Promise<void> {
        event.preventDefault();
        if (runIdRef.current?.value) {
            try {
            const run: Run = await API.getRun(runIdRef.current.value);
            openDrawer(<List>
                <RunListItem run={run} onClick={handleRunItemClick} />
            </List>, 'bottom');
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handleCharacterListItemClick (character: Character): Promise<void> {
        try {
            await API.selectCharacter(character.id);
            updateSessionParameter();
        } catch (error) {
            console.error(error);
        }
    }

    async function handleCharacterSearchListItemClick (character: Character): Promise<void> {
        try {
            if (!session.user) {
                character = await API.selectCharacter(character.id);
            } else {
                character = await API.claimCharacter(character.id);
            }
            closeDrawer();
            handleCharacterListItemClick(character);
        } catch (error) {
            closeDrawer();
            let message: ReactNode = <Typography variant="h2" component="span">{error.message}</Typography>;
            switch (error.status) {
                case HttpStatus.Forbidden: message = <div>
                        <p><Typography variant="h2" component="span">What the nether?!</Typography></p>
                        <p><Typography variant="subtitle1" component="span">Did you really try to use someone else's character?<br />This character is already claimed by someone else.</Typography></p>
                    </div>; break;
            }
            openErrorToast(message);
        }
    }

    async function handleNewCharacterClick (name: string): Promise<void> {
        openDrawer(<CreateCharacter name={name} onErrorClose={handleCreateCharacterError} />, 'left');
    }

    function handleCreateCharacterError (searchValue: string): void {
        setDefaultSearchValue(searchValue);
    }

    function handleCreateRunClick (): void {
        openDrawer(<CreateRun />, 'left');
    }

    return (
        <Grid container spacing={5} justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} md={4}>
                <VideoBackground sources={[{src:'/video/portal_anim.webm', type:'video/webm'}]} className={classes.shadow}>
                    <Grid container alignContent="space-between" alignItems="stretch" justifyContent="center" style={{height: '100%'}}>
                        {!session.user && !session.character && <form className={classes.loginForm} onSubmit={handleLoginFormSubmit}>
                            <Grid item xs={12}><TextField color="secondary" label={'Username'} helperText={'Enter your username'} variant="outlined" inputRef={usernameRef} /></Grid>
                            <Grid item xs={12}><TextField color="secondary" label={'Password'} helperText={'Enter your secret password'} type="password" variant="outlined" inputRef={passwordRef} /></Grid>
                            <Grid item xs={12}><Button variant="contained" color="primary" type="submit">Login</Button></Grid>
                        </form>}
                        {!!session.user?.characters.length && <List className={classes.characterList}>
                            {session.user.characters.map((character: Character) => <CharacterListItem key={character.id} character={character} selected={session.character?.id === character.id} onClick={handleCharacterListItemClick} />)}
                        </List>}
                        {session.user && !session.user.characters.length && <video playsInline autoPlay muted loop controls={false} className={classes.video}>
                            <source src="/video/goblin_talking.webm" />
                        </video>}
                        {!session.user && !session.character && <Divider>or</Divider>}
                        {!session.user && !!session.character && <ScrollableCharacterList>
                            <CharacterListItem key={session.character.id} character={session.character} selected={true} onClick={() => null} />
                        </ScrollableCharacterList>}
                        <CharacterSearch onSearchItemClick={handleCharacterSearchListItemClick} onNewCharacterClick={handleNewCharacterClick} defaultSearchValue={defaultSearchValue} />
                    </Grid>
                </VideoBackground>
            </Grid>
            <Grid item xs={12} md={4}>
            <VideoBackground sources={[{src: '/video/instance_entry.webm', type: 'video/webm'}]} className={classes.shadow}>
                <form className={classes.container} onSubmit={handleJoinSubmit}>
                    <Grid item xs={12}><Typography variant="h2">Join Run</Typography></Grid>
                    <Grid item xs={12}><TextField color="secondary" label={'Run ID'} helperText={'Enter the run id to join'} variant="outlined" inputRef={runIdRef} /></Grid>
                    <Grid item xs={12}><Button variant="contained" color="primary" type="submit">Join</Button></Grid>
                </form>
            </VideoBackground>
            </Grid>
            <Grid item xs={12} md={4}>
                <VideoBackground sources={[{src: '/video/port_stone.webm', type: 'video/webm'}]} className={classes.shadow}>
                    <div className={classes.container}>
                        <Grid item xs={12}><Typography variant="h2">Create Run</Typography></Grid>
                        <Grid item xs={12}><Button variant="contained" color="primary" onClick={handleCreateRunClick}>Create</Button></Grid>
                    </div>
                </VideoBackground>
            </Grid>
        </Grid>
    );
};