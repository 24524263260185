import { Avatar, Badge, capitalize, createStyles, ListItem, ListItemAvatar, ListItemText, withStyles } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import { Character, Race, roleIconMap } from 'shared/Character';
import { colors } from '../Theme';

export interface CharacterListItemAction {
    description: string;
    icon: string;
    onClick: () => void;
}

export interface CharacterListItemProperties {
    character: Character;
    selected: boolean;
    onClick: (character: Character) => void;
    actions?: CharacterListItemAction[];
    children?: ReactNode;
}

export const LevelBadge = withStyles(() =>
    createStyles({
        badge: {
            backgroundColor: 'rgba(0, 0, 0, .5)',
            border: '1px solid #fff',
            color: '#fff',
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '1rem'
        }
    })
)(Badge);

export const RoleBadge = withStyles(() =>
        createStyles({
            root: {
                display: 'inline-flex',
                width: '1.5rem',
                height: '1.5rem',
                marginRight: '.5rem',
                border: 'none',
                borderRadius: '.6rem'
            }
        })
)(Avatar);

export const CharacterListItem: FunctionComponent<CharacterListItemProperties> = ({character, selected, onClick, actions, children}: CharacterListItemProperties): JSX.Element => {
    const race: string = character.race === Race.undead ? 'scourge' : character.race;
    const level: number = character.level < 60 ? 1 : character.level < 70 ? 2 : 3;
    const icon: string = `chr_${race}_${character.gender}_${character.class}0${level}`;
    return (
        <ListItem selected={selected} onClick={() => onClick(character)}>
            <ListItemAvatar>
                <LevelBadge overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} badgeContent={character.level}>
                    <Avatar src={`/icons/${icon}.jpg`} alt={icon} />
                </LevelBadge>
            </ListItemAvatar>
            <ListItemText primary={character.name} secondary={
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '.5rem'}}>
                    <div style={{display: 'flex', width: '100%'}}>
                        <RoleBadge src={`/icons/${roleIconMap[character.role]}`} alt={character.role} variant="rounded" />
                        <span style={{display: 'inline-flex', lineHeight: '1.5rem', verticalAlign: 'middle'}}>{capitalize(character.race)}</span>
                        &nbsp;
                        <span style={{display: 'inline-flex', lineHeight: '1.5rem', verticalAlign: 'middle', color: colors[character.class]}}>{capitalize(character.class)}</span>
                        &nbsp;
                        <span style={{display: 'inline-flex', lineHeight: '1.5rem', verticalAlign: 'middle'}}>&lt;{capitalize(character.server)}&gt;</span>
                    </div>
                    {!!children && <div style={{display: 'flex', alignItems: 'center', marginTop: '.5rem'}}>{children}</div>}
                    {!!actions && actions.length && <div style={{display: 'flex', alignItems: 'center', marginTop: '.5rem'}}>
                        {actions.map((action: CharacterListItemAction) =>
                            <img key={`char-action-${action.description}`} src={action.icon} alt={action.description} />
                        )}
                    </div>}
                </div>
            }></ListItemText>
        </ListItem>
    );
};