import { Avatar, Button, capitalize, Grid, Hidden, List, ListItem, TextField, Typography } from '@material-ui/core';
import { Credentials } from '@sml86/httpjs';
import { FormEvent, FunctionComponent, MutableRefObject, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Character, Role, roleIconMap } from 'shared/Character';
import { dungeonLootList, raidIconMap } from 'shared/Dungeon';
import { Item } from 'shared/Item';
import { Member, Run as IRun } from 'shared/Run';
import { API } from '../api/API';
import { Divider } from '../api/common/Divider';
import { AppContext } from '../App';
import { CharacterListItem } from '../character/CharacterListItem';
import { CharacterSearch } from '../character/CharacterSearch';
import { CreateCharacter } from '../character/CreateCharacter';
import { SmallCharacterListItem } from '../character/SmallCharacterListItem';
import { ItemListItem } from '../item/ItemListItem';
import { useStyles } from './RunStyles';

export interface RunParameter {
    id: string;
}

export const Run: FunctionComponent = (): JSX.Element => {
    const classes = useStyles();
    const match = useRouteMatch<RunParameter>();
    const history = useHistory();
    const { setCurrentRun, session, openDrawer, openErrorToast, closeDrawer, updateSessionParameter, stopVideoBackground, setPadding } = useContext(AppContext);
    const [run, setRun] = useState<IRun>();
    const [items, setItems] = useState<Item[]>([]);
    const [selectedCharacter, setSelectedCharacter] = useState<Character>();
    const [inRun, setInRun] = useState<boolean>(false);
    const pinRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const passwordRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();

    const fetchRun = useCallback(async () => {
        try {
            const run: IRun = await API.getRun(match.params.id);
            setRun(run);
        } catch (error) {
            console.error(error);
        }
    }, [match.params.id]);

    const fetchItems = useCallback(async () => {
        try {
            if (run && !items.length) {
                const all: Item[] = await API.getItems(run.dungeon);
                setItems(all);
            }
        } catch (error) {
            console.error(error);
        }
    }, [items.length, run]);

    useEffect(() => {
        fetchRun();
    }, [fetchRun]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        setCurrentRun(match.params.id);
    }, [match.params.id, setCurrentRun]);

    useEffect(() => {
        if (session.character) {
            setSelectedCharacter(session.character);
            if (run) {
                if (run.member.pending[session.character.id] || run.member.confirmed[session.character.id]) setInRun(true);
                else {
                    const chars: Character[] = session.user?.characters || [];
                    for (const char of chars) {
                        if (run.member.pending[char.id] || run.member.confirmed[char.id]) {
                            setInRun(true);
                            break;
                        }
                    }
                }
            }
        }
    }, [session.character, session.user, run]);

    useEffect(() => {
        stopVideoBackground();
    }, [stopVideoBackground]);

    useEffect(() => {
        if ((session.character && run?.member.confirmed[session.character.id]) || run?.authorized) setPadding(false);
    }, [setPadding, run, session.character]);

    async function joinRunWithCharacter (character: Character): Promise<void> {
        try {
            if (run) {
                let credentials: Credentials|undefined;
                if (run?.private) {
                    credentials = {
                        username: character.name,
                        password: pinRef.current?.value || ''
                    };
                }
                const updatedRun: IRun = await API.joinRun(run.id, character.id, credentials);
                setRun(updatedRun);
                closeDrawer();
                updateSessionParameter();
            }
        } catch (error) {
            const message: ReactNode = <Typography variant="h2" component="span">{``}</Typography>;
            openErrorToast(message);
        }
    }

    function handleCharacterListItemClick (character: Character): void {
        setSelectedCharacter(character);
    }

    async function handleCharacterSearchListItemClick (char: Character): Promise<void> {
        setSelectedCharacter(char);
        await API.selectCharacter(char.id);
        updateSessionParameter();
        closeDrawer();
    }

    function handleNewCharacterClick (name: string): void {
        openDrawer(<CreateCharacter name={name} onErrorClose={() => undefined} onSuccess={handleCharacterSearchListItemClick} realm={run?.server} />, 'left');
    }

    async function handleConfirmButtonClick (): Promise<void> {
        if (selectedCharacter && run?.member.pending[selectedCharacter.id]) {
            try {
                const updated: IRun = await API.confirmCharacterOnRun(run.id, selectedCharacter.id);
                setRun(updated);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handleRemoveButtonClick (): Promise<void> {
        if (selectedCharacter && run?.member.confirmed[selectedCharacter.id]) {
            try {
                const updated: IRun = await API.unconfirmCharacterOnRun(run.id, selectedCharacter.id);
                setRun(updated);
            } catch (error) {
                console.error(error);
            }
        }
    }

    function handleRefreshButtonClick (): void {
        fetchRun();
    }

    function handleJoinRunClick (): void {
        if (session.character) {
            joinRunWithCharacter(session.character);
        }
    }

    async function handleDeleteButtonClick (): Promise<void> {
        if (run && selectedCharacter) {
            try {
                const updated: IRun = await API.deleteCharacterOnRun(run.id, selectedCharacter.id);
                setRun(updated);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handleLeaveButtonClick (): Promise<void> {
        if (run) {
            try {
                await API.leaveRun(run.id);
                history.push('/');
            } catch (error) {
                console.error(error);
            }
        }
    }

    async function handlePriorityButtonClick (item: Item, priority: number): Promise<void> {
        try {
            closeDrawer();
            if (run && session.character) {
                const updated: IRun = await API.addPrioItem(run.id, session.character.id, item.itemId, priority);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleItemListItemClick (item: Item): Promise<void> {
        const buttonList: JSX.Element[] = [];
        const priorities: number = run?.priorities || 0;
        for (let i: number = 0; i < priorities; ++i) {
            buttonList.push(<Grid key={`prio-item-small-${item.itemId}`} item xs={2}><Button variant="outlined" color="primary" onClick={() => handlePriorityButtonClick(item, i+1)}>{i+1}</Button></Grid>);
        }
        closeDrawer(() => openDrawer(<Grid container>
            <Grid item xs={12}><Typography variant="h4">Select priority for</Typography></Grid>
            <Grid item xs={12}>
                <List><ItemListItem key={`item-list-item-${item.itemId}`} item={item} /></List>
            </Grid>
            {buttonList.map((button: JSX.Element) => button)}
        </Grid>, 'left'));
    }

    async function handleAdminSubmit (event: FormEvent): Promise<void> {
        event.preventDefault();
        if (run && passwordRef.current?.value) {
            try {
                const updated: IRun = await API.authorizeRun(run.id, passwordRef.current.value);
                closeDrawer();
                setRun(updated);
                updateSessionParameter();
            } catch (error) {
                console.error(error);
            }
        }
    }

    function handleAdminClick (): void {
        openDrawer(<form onSubmit={handleAdminSubmit}>
            <Grid container style={{width: '100%', padding: '1rem 0'}} spacing={2}>
                <Grid item><Typography variant="h4">Authorize for this run</Typography></Grid>
                <Grid item><TextField color="secondary" label={'Password'} variant="outlined" inputRef={passwordRef} defaultValue={``} /></Grid>
                <Grid item><Button variant="contained" color="primary" type="submit" style={{margin: '.2rem'}}>Authorize</Button></Grid>
            </Grid>
        </form>, 'bottom');
    }

    async function handleAddItemClick (): Promise<void> {
        openDrawer(<List>
            {items.map((item: Item) => <ItemListItem item={item} onClick={handleItemListItemClick} />)}
        </List>, 'left');
    }

    async function handleStartButtonClick (): Promise<void> {
        try {
            if (run) {
                const updated: IRun = await API.lockRun(run.id);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleUnlockButtonClick (): Promise<void> {
        try {
            if (run) {
                const updated: IRun = await API.unlockRun(run.id);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleEndButtonClick (): Promise<void> {
        try {
            if (run) {
                const updated: IRun = await API.endRun(run.id);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleAddCharacterToRunClick (character: Character): Promise<void> {
        try {
            closeDrawer();
            if (run) {
                const updated: IRun = await API.joinRun(run.id, character.id);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function handleNewCharacterBehalfClick (search: string): void {
        closeDrawer(() => {
            const name: string = search;
            openDrawer(<CreateCharacter name={name} onErrorClose={() => undefined} onSuccess={handleAddCharacterToRunClick} behalf={true} realm={run?.server} />, 'left');
        });
    }

    function handleAddCharacterSearchSubmit (value: string, callback: (value: string) => Promise<void>): void {
        closeDrawer(callback.bind(null, value));
    }

    function handleAddCharacterButtonClick (): void {
        openDrawer(<Grid container>
            <Grid item xs={12}><Typography variant="h4">Add Character to run</Typography></Grid>
            <Grid container item xs={12}>
                <CharacterSearch onSearchItemClick={handleAddCharacterToRunClick} onNewCharacterClick={handleNewCharacterBehalfClick} onSearchSubmit={handleAddCharacterSearchSubmit} style={{width: 'auto'}}/>
            </Grid>
        </Grid>, 'bottom');
    }

    async function handleItemActionClick (item: Item): Promise<void> {
        try {
            if (run && selectedCharacter) {
                const updated: IRun = await API.removePrioItem(run.id, selectedCharacter.id, item.itemId);
                setRun(updated);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function renderCharacters (members: {[id: string]: Member}, role?: Role): ReactNode {
        let values: Member[] = Object.values(members);
        if (role) values = values.filter((member: Member) => member.role === role);
        return <List>
            {values.map((member: Member) => {
                const character = {...member.character, role: member.role};
                return <SmallCharacterListItem key={member.character.id} character={character} selected={selectedCharacter?.id === member.character.id} onClick={() => handleCharacterListItemClick(member.character)}>
                    {run?.priorities && (run.locked || run.authorized) && <List className={classes.memberPrioList}>{member.priorities.map((id: number) => {
                        const item: Item|undefined = items.find((value: Item) => value.itemId === id);
                        return item ? <ItemListItem item={item} small={true} /> : <></>;
                    })}</List>}
                </SmallCharacterListItem>;
            })}
        </List>;
    }

    return !!run ? <Grid container className={classes.root}>
        <Grid container item xs={12} md={9} spacing={4}>
            <Grid container item xs={12} spacing={4}>
                <Grid item xs={4}>
                    <Avatar src={`/icons/${raidIconMap[run.dungeon]}.jpg`} alt={dungeonLootList[run.dungeon].name} />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h2">{dungeonLootList[run.dungeon].name} ({Object.keys(run.member.confirmed).length}/{dungeonLootList[run.dungeon].player})</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '.5rem'}}>
                        {!!run.private && <img src="/icons/inv_misc_key_05.jpg" alt="private" width={20} height={20} style={{display: 'inline-block', marginRight: '.5rem'}} />}
                        <span>{run.start.format('mmm dd, yyyy')}</span>
                        {!!run.creator?.name && <>&nbsp; by &nbsp; <span>{run.creator.name}</span></>}
                        &nbsp; on &nbsp; <span>&lt;{capitalize(run.server)}&gt;</span>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    {session.character && !inRun && <Grid item xs={12}>
                        <Typography variant="h4">Join with</Typography>
                        <List><CharacterListItem character={session.character} selected={false} onClick={() => undefined}/></List>
                        <Divider>or</Divider>
                    </Grid>}
                    {!inRun && <Grid container item xs={12}>
                        <CharacterSearch onSearchItemClick={handleCharacterSearchListItemClick} onNewCharacterClick={handleNewCharacterClick} style={{width: 'auto'}}/>
                    </Grid>}
                    {!inRun && <Grid item xs={12} style={{marginTop: '4rem'}}>
                        {run.private && <Grid item style={{marginBottom: '1rem'}}><TextField color="secondary" variant="outlined" label={'PIN'} inputRef={pinRef}/></Grid>}
                        <Grid item><Button variant="contained" color="primary" onClick={handleJoinRunClick}>Join</Button></Grid>
                    </Grid>}
                    {session.character && run.member.pending[session.character.id] && <Typography>Pending...</Typography>}
                    {(run.authorized || (session.character && run.member.confirmed[session.character.id])) && <Grid container item xs={12}>
                        <Grid container item xs={12} sm={6} md={3} alignContent="flex-start">
                            <Grid container item xs={12} spacing={2}>
                                <Grid item><Avatar src={`/icons/${roleIconMap[Role.tank]}`} alt="Tanks" variant="rounded" className={classes.midAvatar} /></Grid>
                                <Grid item><Typography>Tanks</Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>{renderCharacters(run.member.confirmed, Role.tank)}</Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={3} alignContent="flex-start">
                            <Grid container item xs={12} spacing={2}>
                                <Grid item><Avatar src={`/icons/${roleIconMap[Role.healer]}`} alt="Healer" variant="rounded" className={classes.midAvatar} /></Grid>
                                <Grid item><Typography>Healer</Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>{renderCharacters(run.member.confirmed, Role.healer)}</Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={3} alignContent="flex-start">
                            <Grid container item xs={12} spacing={2}>
                                <Grid item><Avatar src={`/icons/${roleIconMap[Role.caster]}`} alt="Caster" variant="rounded" className={classes.midAvatar} /></Grid>
                                <Grid item><Typography>Caster</Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>{renderCharacters(run.member.confirmed, Role.caster)}</Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={3} alignContent="flex-start">
                            <Grid container item xs={12} spacing={2}>
                                <Grid item><Avatar src={`/icons/${roleIconMap[Role.melee]}`} alt="Melee" variant="rounded" className={classes.midAvatar} /></Grid>
                                <Grid item><Typography>Melee</Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>{renderCharacters(run.member.confirmed, Role.melee)}</Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={3} alignContent="flex-start">
                            <Grid container item xs={12} spacing={2}>
                                <Grid item><Avatar src={`/icons/${roleIconMap[Role.ranged]}`} alt="Ranged" variant="rounded" className={classes.midAvatar} /></Grid>
                                <Grid item><Typography>Ranged</Typography></Grid>
                            </Grid>
                            <Grid item xs={12}>{renderCharacters(run.member.confirmed, Role.ranged)}</Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12} md={3}>
            {session.character && run.member.confirmed[session.character.id] && <>
                {!!run.priorities && <Grid item xs={12}>
                    <Typography>Priority items</Typography>
                    <List>{run.member.confirmed[session.character.id].priorities.map((id: number) => {
                        const item: Item|undefined = items.find((value: Item) => value.itemId === id);
                        return item ? <ItemListItem key={`prio-item-${id}`} item={item} action={!run.locked} onActionClick={handleItemActionClick} /> : <ListItem key={`dummy-list-item-${id}`}>{id}</ListItem>;
                    })}</List>
                </Grid>}
            </>}
            {run.authorized && <Grid container item xs={12} sm={6} md={12} spacing={0} alignContent="flex-start">
                <Grid container spacing={2}>
                    <Grid item><Avatar src="/icons/achievement_bg_grab_cap_flagunderxseconds.jpg" alt="Pending" variant="rounded" className={classes.midAvatar} /></Grid>
                    <Grid item><Typography>Pending</Typography></Grid>
                </Grid>
                <Grid item xs={12}>{renderCharacters(run.member.pending)}</Grid>
            </Grid>}
            <Grid container item xs={12} sm={6} md={12} spacing={4}>
                {run.authorized && <><Grid container>
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleAddCharacterButtonClick}>Add Character</Button></Grid>
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleConfirmButtonClick}>Confirm</Button></Grid>
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleRemoveButtonClick}>Remove</Button></Grid>
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="secondary" size="small" onClick={handleDeleteButtonClick}>Delete</Button></Grid>
                </Grid>
                <Grid container>
                    {!run.locked && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleStartButtonClick}>Start</Button></Grid>}
                    {!!run.locked && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleUnlockButtonClick}>Unlock</Button></Grid>}
                    {!!run.locked && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleEndButtonClick}>End</Button></Grid>}
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleRefreshButtonClick}>Refresh</Button></Grid>
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="secondary" size="small" onClick={handleRefreshButtonClick}>Close</Button></Grid>
                </Grid></>}
                {!!session.character && <Grid container>
                    {!!run.priorities && run.member.confirmed[session.character.id].priorities.length < run.priorities && !run.locked && !!run.member.confirmed[session.character.id] && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleAddItemClick}>Add Item</Button></Grid>}
                    {!run.authorized && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleRefreshButtonClick}>Refresh</Button></Grid>}
                    {!run.authorized && <Grid item style={{margin: '4px'}}><Button variant="contained" color="primary" size="small" onClick={handleAdminClick}>Admin</Button></Grid>}
                    <Grid item style={{margin: '4px'}}><Button variant="contained" color="secondary" size="small" onClick={handleLeaveButtonClick}>Leave</Button></Grid>
                </Grid>}
            </Grid>
            <Hidden mdUp><div style={{height: '10rem', width: '100%'}}></div></Hidden>
        </Grid>
    </Grid> :
    <Grid container item xs={12} alignItems="center" justifyContent="center" alignContent="center">
        <Typography variant="h3" style={{width: '100%', textAlign: 'center'}}>Seems you missed your dungeon group.</Typography>
        <video playsInline autoPlay muted loop controls={false} className={classes.video}>
            <source src="/video/spirit_healer.webm" type="video/webm" />
        </video>
    </Grid>;
};