import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden'

    },
    video: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center',
        overflow: 'hidden'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'transparent'
    }
}));