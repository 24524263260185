import { Grid, List, TextField, Typography } from '@material-ui/core';
import { HttpError, HttpStatus } from '@sml86/httpjs';
import { FunctionComponent, MutableRefObject, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { Character, classRoleMap, Role, roleIconMap } from 'shared/Character';
import { MAX_LEVEL } from 'shared/Constants';
import { User } from 'shared/User';
import { API } from '../api/API';
import { AppContext } from '../App';
import { CharacterListItem } from './CharacterListItem';
import { useStyles } from './CharacterStyles';

export interface UpdateCharacterProperties {
    character: Character;
}

export const UpdateCharacter: FunctionComponent<UpdateCharacterProperties> = ({character}: UpdateCharacterProperties): JSX.Element => {
    const classes = useStyles();
    const { session, setSession, closeDrawer, openErrorToast } = useContext(AppContext);
    const [selectedRole, setSelectedRole] = useState<Role>(character.role);
    const levelInputRef: MutableRefObject<HTMLInputElement|undefined> = useRef<HTMLInputElement>();
    const availableRoles = classRoleMap[character.class];

    const handleError = useCallback((error: Error|HttpError) => {
        let message: ReactNode = <Typography variant="h2" component="span">{error.message}</Typography>;
        switch ((error as HttpError).status) {
            case HttpStatus.NotModified: message = <div>
                    <p><Typography variant="h2" component="span">Are you serious?!</Typography></p>
                    <p><Typography variant="subtitle1" component="span">Did you really try to update a character that did not change?<br />What did you think would happen?</Typography></p>
                </div>; break;
        }
        closeDrawer();
        openErrorToast(message);
    }, [closeDrawer, openErrorToast]);

    const updateCharacter = useCallback(async (role: Role, level: number) => {
        try {
            const updated: Character = await API.updateCharacter(character.id, level, role);
            let user: User|null = null;
            if (session.user) {
                const characters: Character[] = [...session.user.characters];
                for (let i = 0; i < characters.length; ++i) {
                    if (characters[i].id === updated.id) characters.splice(i, 1, updated);
                }
                user = {...session.user, characters};
            }
            setSession({...session, character: updated, user});
            closeDrawer();
        } catch (error) {
            handleError(error);
        }
    }, [character.id, closeDrawer, handleError, session, setSession]);

    function handleLevelChange (): void {
            const level: number = parseInt(levelInputRef.current?.value || '0');
            if (level > character.level && level <= MAX_LEVEL) {
                updateCharacter(character.role, level);
            } else {
                handleError(new HttpError(HttpStatus.NotModified, 'Not Modified'));
            }
    }

    function handleRoleClick (role: Role): void {
        if (role !== character.role) {
            updateCharacter(role, character.level);
            setSelectedRole(role);
        } else {
            handleError(new HttpError(HttpStatus.NotModified, 'Not Modified'));
        }
    }

    return <Grid container style={{padding: '1rem'}}>
        <Typography variant="h3">Selected Character</Typography>
        <List>
            <CharacterListItem character={character} selected={false} onClick={() => undefined}>
                {availableRoles.length > 1 && availableRoles.map((role: string) =>
                    <img key={`char-role-${role}`} src={`/icons/${roleIconMap[role]}`} alt={role} className={`${selectedRole === role ? `${classes.actionItem} ${classes.selected}` : classes.actionItem}`} onClick={() => handleRoleClick(role as Role)} />)}
                {character.level < MAX_LEVEL && <TextField type="number" size="small" color="secondary" label={'Level'} variant="outlined" inputRef={levelInputRef} defaultValue={character.level} style={{width: '5rem'}} inputProps={{min: character.level, max: MAX_LEVEL}} onChange={handleLevelChange} />}
            </CharacterListItem>
        </List>
    </Grid>;
};