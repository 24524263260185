import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './serviceWorkerRegistration';

import { App } from './App';

import './index.css';


declare global {
  interface Date {
    format: (format: string, days?: string[], months?: string[]) => string;
  }
}

// eslint-disable-next-line no-extend-native
Date.prototype.format = function (format: string, days?: string[], months?: string[]) {
  let str = format || 'yyyy-mm-dd';
  days = days || ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  months = months || ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  if (typeof str !== 'string' || !(days instanceof Array) || days.length < 7 || !(months instanceof Array) || months.length < 12) {
      throw new TypeError();
  }
  str = str.replace(/(y+|m+|d+|h+|i+|s+)/g, '{$1}');
  str = str.replace('{yyyy}', `${this.getFullYear()}`);
  str = str.replace('{yy}', `${this.getFullYear()}`);
  str = str.replace('{mmm}', months[this.getMonth()]);
  str = str.replace('{mm}', `${zero(this.getMonth() + 1)}`);
  str = str.replace('{m}', `${this.getMonth() + 1}`);
  str = str.replace('{ddd}', days[this.getDay()]);
  str = str.replace('{dd}', `${zero(this.getDate())}`);
  str = str.replace('{d}', `${this.getDate()}`);
  str = str.replace('{hh}', `${zero(this.getHours())}`);
  str = str.replace('{h}', `${this.getHours()}`);
  str = str.replace('{ii}', `${zero(this.getMinutes())}`);
  str = str.replace('{i}', `${this.getMinutes()}`);
  str = str.replace('{ss}', `${zero(this.getSeconds())}`);
  str = str.replace('{s}', `${this.getSeconds()}`);
  return str;
};

function zero (n: number) {
  return n < 10 ? '0' + n : n;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();
