import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors, translateHexRgba } from '../Theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    searchForm: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        marginBottom: '1rem'
    },
    lineMargin: {
        marginBottom: '1rem'
    },
    banner: {
        border: `1px solid ${colors.poor}`,
        cursor: 'pointer',
        borderRadius: '.3rem'
    },
    actionItem: {
        border: `1px solid ${colors.poor}`,
        cursor: 'pointer',
        borderRadius: '.2rem',
        width: '2rem',
        height: '2rem',
        margin: '0.3rem'
    },
    selected: {
        border: `1px solid ${colors.system}`,
        boxShadow: `0 0 2rem ${translateHexRgba(colors.system, .7)}`
    },
    hordeBackground: {
        background: translateHexRgba(colors.deathKnight, .3),
        borderRadius: '.2rem',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '166px',
            marginLeft: '2rem'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem'
        }
    },
    allianceBackground: {
        background: translateHexRgba(colors.shaman, .3),
        borderRadius: '.2rem',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '166px'
        }
    }
}));